import React from 'react';
import Background from './components/background/background.component';

import './App.css';

function App() {

  return (
    <div className="App">
      <Background />
    </div>
  );
}

export default App;
